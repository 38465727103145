import Icon from 'components/icon/icon'
import styles from './stars.module.scss'
import { cn } from 'utils/helpers'

export default function Stars({ number, className }) {
    const range = [1, 2, 3, 4, 5]

    return (
        <div className={cn(styles.stars, className)}>
            {range.map(no => (
                <Icon key={no} icon="star" className={cn(number >= no && styles.active)} />
            ))}
        </div>
    )
}
