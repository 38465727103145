import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Heading from 'components/heading/heading'
import Like from 'components/like/like'
import Image from 'components/image/image'
import Badges from 'components/badges/badges'
import Price from 'components/price/price'
import Rating from 'components/rating/rating'
import { getRoute, cn, excerpt } from 'utils/helpers'
import { trackProductClick } from 'utils/analytics/events'
import { connectHitInsights } from 'react-instantsearch-dom'
import styles from './card.module.scss'
import { useInView } from 'react-intersection-observer'
import useAppState from 'utils/state'

export default function Card({ hit, insights, hideRating }) {
    const Component = insights ? connectHitInsights(window.aa)(Child) : Child
    return <Component hit={hit} hideRating={hideRating} insights={insights} />
}

function Child({ hit, insights, hideRating }) {
    const { queueProductImpression } = useAppState()
    const { name, image, available, code, virtual } = hit
    const { pathname } = useRouter()
    const [tracked, setTracked] = useState(false)
    const asPoints = pathname === getRoute('loyalty-program')
    const { ref, inView } = useInView({
        threshold: 0.9
    })
    useEffect(() => {
        if (!tracked && inView) {
            setTracked(true)
            queueProductImpression(hit)
        }
    }, [inView])

    let url = getRoute('product', hit)
    if (hit.__queryID) {
        url += `?queryID=${hit.__queryID}`
    }

    const handleClick = () => {
        if (insights) {
            insights('clickedObjectIDsAfterSearch', {
                eventName: 'Product Clicked'
            })
        }
        trackProductClick(hit, !!insights)
    }

    return (
        <article ref={ref} itemScope itemType="http://schema.org/Product" className={styles.card}>
            <div onClick={handleClick} className={cn(styles.img, virtual && styles.virtualImage)}>
                {virtual ? (
                    <div className={styles.virtualImage} />
                ) : (
                    <>
                        <Image
                            itemProp="image"
                            src={image}
                            alt={name}
                            layout="responsive"
                            objectFit="contain"
                            width={220}
                            height={220}
                        />
                        <Like product={hit}>
                            <Link onClick={handleClick} href={url}>
                                <a aria-label={name}></a>
                            </Link>
                        </Like>
                    </>
                )}
            </div>
            {virtual ? (
                <>
                    <div className={styles.price}>&nbsp;</div>
                    <Heading size="16" tag="h3" body>
                        &nbsp;
                        <br />
                        <br />
                    </Heading>
                    <div className={styles.brand}>&nbsp;</div>
                </>
            ) : (
                <>
                    <div className={styles.price} itemProp="offers" itemScope itemType="http://schema.org/Offer">
                        <meta
                            itemProp="availability"
                            content={`https://schema.org/${available ? 'InStock' : 'OutOfStock'}`}
                        />
                        <meta itemProp="priceCurrency" content="EUR" />
                        <Price asPoints={asPoints} itemProp="price" product={hit} />
                        <div itemProp="seller" itemType="http://schema.org/Organization" itemScope>
                            <meta itemProp="name" content={hit.account_name} />
                        </div>
                        <link itemProp="url" href={url} />
                        <Badges product={hit} />
                    </div>
                    <meta itemProp="sku" content={code} />
                    <Heading itemProp="name" size="16" tag="h3" body>
                        <Link onClick={handleClick} href={url}>
                            <a>{excerpt(name)}</a>
                        </Link>
                    </Heading>
                    {hit.brand_name && (
                        <Link href={getRoute('brand', hit)}>
                            <a itemProp="brand" className={styles.brand}>
                                {hit.brand_name}
                            </a>
                        </Link>
                    )}
                    {!!hit.review_count && (
                        <div itemProp="aggregateRating" itemScope itemType="https://schema.org/AggregateRating">
                            <meta itemProp="ratingValue" content={hit.review_rating} />
                            <meta itemProp="reviewCount" content={hit.review_count} />
                        </div>
                    )}
                    {!!hit.review_count && !hideRating && (
                        <Rating rating={hit.review_rating} count={hit.review_count} />
                    )}
                </>
            )}
        </article>
    )
}
