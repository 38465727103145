import SliderNavs from 'components/slider-navs/slider-navs'
import Card from 'components/card/card'
import AccountItem from 'components/account-item/account-item'
import styles from './slider.module.scss'
import { cn } from 'utils/helpers'
import Link from 'next/link'

const virtualItems = [{ virtual: true, slug: 'virtual' }]

export default function Slider({ virtual, children, products, navs, url, withSidebar, hideRating, accounts }) {
    const items = virtual ? virtualItems : products
    const Component = accounts ? AccountItem : Card

    return (
        <div className={cn(styles.container, virtual && styles.virtual, 'mb72')}>
            <div className={cn(styles.top, 'mb36')}>
                {children}
                {navs && <SliderNavs pageUrl={url} pageName="Visos prekės" />}
            </div>
            <div className={styles.slider}>
                {items.map(product => (
                    <div key={product.slug} className={cn(styles.slide, withSidebar && styles.alt)}>
                        <Component hit={product} hideRating={hideRating} showDiscount={false} />
                    </div>
                ))}
                {url && (
                    <div className={cn(styles.slide, withSidebar && styles.alt, styles.moreSlide, 'desktopHide')}>
                        <div className={styles.more}>
                            <Link href={url}>
                                <a aria-label="Daugiau">Visos prekės</a>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
