import Link from 'next/link'
import Image from 'components/image/image'
import styles from './account-item.module.scss'
import Heading from 'components/heading/heading'

export default function Brand({ hit, showDiscount = true }) {
    const { name, image, slug, short_description, discount } = hit
    const url = `/parduotuves/${slug}`

    return (
        <div className={styles.card}>
            <div className={styles.wrap}>
                <div className={styles.img}>
                    <div className={styles.logo}>
                        <Image
                            src={image}
                            alt={name}
                            layout="responsive"
                            objectFit="contain"
                            width={110}
                            height={110}
                        />
                    </div>
                    <Link href={url}>
                        <a aria-label={name}></a>
                    </Link>
                </div>
                {!!discount && showDiscount && <div className={styles.discount}>-{discount}%</div>}
                <Heading itemProp="name" size="18" tag="h3" className={styles.name}>
                    <Link href={url}>
                        <a itemProp="brand">{name}</a>
                    </Link>
                </Heading>
                <div className={styles.description}>{short_description}</div>
            </div>
        </div>
    )
}
