import Svg from 'components/svg/svg'
import Tooltip from 'components/tooltip/tooltip'
import styles from './badges.module.scss'
import { cn } from 'utils/helpers'
import Image from 'components/image/image'
import useAppState from 'utils/state'

export default function Badges({ product }) {
    const { coupon } = useAppState()
    let { available, percentage_discount, has_free_shipping, ukraina, account_slug } = product

    if (coupon && product.account_discount && (!coupon.account_slug || coupon.account_slug === account_slug)) {
        // product.simple_price &&
        percentage_discount = coupon.account_slug
            ? coupon.max
            : coupon.max < product.account_discount
            ? coupon.max
            : product.account_discount
    }

    return (
        <div className={styles.badges}>
            {!available && (
                <Tooltip content="Laikinai neturime">
                    <div className={cn(styles.badge, styles.yellow)}>
                        <Svg name="temp-unavailable" />
                    </div>
                </Tooltip>
            )}
            {!!available && !!percentage_discount && (
                <Tooltip content="Nuolaida">
                    <div className={cn(styles.badge, styles.red)}>-{percentage_discount}%</div>
                </Tooltip>
            )}
            {!!available && !!has_free_shipping && (
                <Tooltip content="Nemokamas siuntimas">
                    <div className={cn(styles.badge, styles.green)}>
                        <Svg name="free-shipping" />
                    </div>
                </Tooltip>
            )}
            {!!ukraina && (
                <Tooltip content="Pinigai bus skirti nukentėjusiems Ukrainos žmonėms">
                    <div className={styles.ukraina}>
                        <Image alt="Ukrainos vėliava" src="/img/ukraine.gif" width="144" height="100" />
                    </div>
                </Tooltip>
            )}
        </div>
    )
}
