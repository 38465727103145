import Stars from 'components/stars/stars'
import styles from './rating.module.scss'

export default function A({ rating, count }) {
    return (
        <div className={styles.rating}>
            <Stars number={Math.ceil(rating)} />
            {count && <div className={styles.count}>({count})</div>}
        </div>
    )
}
