import Head from 'next/head'
const env = process.env.NEXT_PUBLIC_APP_ENV

export default function Meta({
    title,
    description,
    image = '/img/social.jpeg',
    noindex,
    appName = ' | Lietuviška prekė'
}) {
    return (
        <Head>
            {(noindex || env !== 'prod') && <meta name="robots" content="noindex, nofollow" />}
            {title && (
                <>
                    <title>{`${title}${appName}`}</title>
                    <meta name="title" content={`${title} | Lietuviška prekė`} />
                    <meta itemProp="name" content={`${title} | Lietuviška prekė`} />
                    <meta property="og:title" content={`${title} | Lietuviška prekė`} />
                </>
            )}
            {description && (
                <>
                    <meta property="og:description" content={description} />
                    <meta itemProp="description" content={description} />
                    <meta name="description" content={description} />
                </>
            )}
            <meta itemProp="image" content={image} />
            <meta property="og:image" content={image} />
            <meta name="twitter:image" content={image} />
        </Head>
    )
}
