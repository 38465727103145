import Icon from 'components/icon/icon'
import styles from './like.module.scss'
import useAppState from 'utils/state'
import { trackAddToWishlist } from 'utils/analytics/events'
import { cn } from 'utils/helpers'

export default function Like({ product, children, visible }) {
    const { id } = product
    const { likes, likeUnlike } = useAppState()

    const handleClick = () => {
        if (likes.indexOf(id) === -1) {
            trackAddToWishlist(product)
        }
        likeUnlike(id)
    }

    return (
        <div className={cn(styles.container, visible && styles.visible)}>
            <Icon
                icon="heart"
                btn
                label="Pamėgti"
                onClick={handleClick}
                className={cn(styles.like, likes && Array.isArray(likes) && likes.indexOf(id) > -1 && styles.active)}
            />
            {children}
        </div>
    )
}
