import styles from './layer.module.scss'
import { cn } from 'utils/helpers'

export default function Layer({ children, className }) {
    return (
        <div className={cn(styles.layer, className)}>
            <div className={styles.wrap}>{children}</div>
        </div>
    )
}
