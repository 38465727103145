import Icon from 'components/icon/icon'
import Btn from 'components/btn/btn'

export default function SliderNavs({ pageUrl, pageName }) {
    return (
        <div>
            {pageUrl && (
                <>
                    <Btn style="light" href={pageUrl} className="mobileHide">
                        {pageName}
                    </Btn>
                    <Icon
                        icon="arrow-right"
                        href={pageUrl}
                        label={pageName}
                        style="light"
                        className="tabletDesktopHide"
                    />
                </>
            )}
        </div>
    )
}
